<template>
  <div class="settingsPage">
    <div v-if="business" class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <ValidationObserver
              v-slot="{ handleSubmit, invalid, touched }"
              ref="settingsForm"
            >
              <form
                class="authentication-form"
                @submit.prevent="handleSubmit(updateSettings)"
              >
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Marketing Number</label> <br />
                      <input
                        v-if="business && business.phone_number"
                        v-model="business.phone_number"
                        type="text"
                        class="form-control"
                        readonly
                      />
                      <button
                        v-else
                        type="button"
                        class="btn btn-danger btn-sm"
                        @click="showAreaModal"
                      >
                        Assigned number
                      </button>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Currency</label>
                      <select v-model="business.currency" class="form-control">
                        <option
                          v-for="currency in currencies"
                          :key="currency.name"
                          :value="currency.name"
                          >{{ currency.label }}</option
                        >
                      </select>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Date format</label>
                      <select v-model="business.date_format" class="form-control">
                        <option
                          v-for="datetime in dateFormats"
                          :key="datetime"
                          :value="datetime"
                          >{{ datetime }}</option
                        >
                      </select>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <SelectInput
                      v-model="business.timezone"
                      :options="timezones"
                      label="Timezone"
                    />
                  </div>
                  <div class="col-md-4">
                    <label>
                      Auto responder message
                    </label>
                    <textarea
                      v-model="business.auto_responder_message"
                      name="auto_responder_message"
                      class="form-control"
                      rows="2"
                      style="resize:none;"
                    ></textarea>
                    <SMSLength :text="business.auto_responder_message" />
                  </div>
                  <div class="col-md-4">
                    <label>
                      Opt out message
                    </label>
                    <textarea
                      v-model="business.opt_message"
                      name="opt_message"
                      class="form-control"
                      rows="2"
                      style="resize:none;"
                    ></textarea>
                    <SMSLength :text="business.opt_message" />
                  </div>
                </div>
                <hr />
                <h5 class="h5">Company</h5>
                <div class="row">
                  <div class="col-md-4">
                    <TextInput
                      v-model="business.name"
                      name="Business Name"
                      label="Business Name"
                      rules="required"
                    />
                  </div>
                  <div class="col-md-4">
                    <PhoneInput
                      v-model="user.phone_number"
                      name="Default Business Number"
                      label="Default Business Number"
                      rules="required|phone"
                      :disabled-fetching-country="false"
                      :only-countries="onlyCountries"
                    />
                  </div>
                </div>
                <b-form-group>
                  <b-button
                    variant="primary"
                    :disabled="loadingSave || (invalid && touched)"
                    type="submit"
                  >
                    <b-spinner v-if="loadingSave" small />
                    <span v-else>Save</span>
                  </b-button>
                </b-form-group>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
      <div v-if="user" class="col-lg-6 col-md-12">
        <div class="card">
          <div class="card-body">
            <ValidationObserver v-slot="{ invalid }" ref="broadcastersForm">
              <div class="row">
                <div class="col-md-12 alert text-left">
                  <span class="d-block">
                    In order to be able to send a broadcast to your subscribers
                    from your mobile phone you will need to add your mobile
                    phone number below.
                  </span>
                  <span class="d-block mt-3"
                    >To broadcast from your cell phone simply send the word
                    broadcast followed by your message to your
                    marketing number {{ user.business.phone_number }}.</span
                  >
                  <span class="d-block mt-3"
                    >To broadcast to specific keywords simply text the word
                    broadcast (keyword1, keyword2) followed by your message to
                    your marketing number
                    {{ user.business.phone_number }}.</span
                  >
                </div>
              </div>
              <table class="table">
                <thead class="thead-light">
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Phone Number</th>
                    <th class="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(contact, index) in user.business.broadcasters"
                    :key="contact.id"
                  >
                    <td>#{{ index + 1 }}</td>
                    <td>
                      <TextInput v-model="contact.name" margin-bottom="0px" />
                    </td>
                    <td>
                      <PhoneInput
                        v-model="contact.phone_number"
                        rules="required|phone"
                        margin-bottom="0px"
                        name="phone"
                      />
                    </td>
                    <td class="text-center">
                      <button
                        class="btn btn-danger btn-sm"
                        @click="removeBroadcaster(index)"
                      >
                        <i class="uil uil-trash-alt"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="row">
                <div class="col-md-12 text-right">
                  <button
                    class="btn btn-primary btn"
                    :disabled="invalid || loadingUpdate"
                    @click="addBroadcaster"
                  >
                    <i class="uil uil-plus"></i> New Broadcast Number
                  </button>
                  <button
                    class="ml-2 btn btn-primary"
                    :disabled="invalid || loadingUpdate"
                    @click="updateBroadcasters"
                  >
                    <b-spinner v-if="loadingUpdate" small />
                    <span v-else><i class="uil uil-upload"></i> Save</span>
                  </button>
                </div>
              </div>
            </ValidationObserver>
          </div>
        </div>
      </div>
      <div v-if="user" class="col-lg-6 col-md-12">
        <div class="card">
          <div class="card-body">
            <h5 class="h5">Message limit</h5>
            <div class="text-center">
              <strong>{{ business.no_messages === null ? 'Unlimited' : `${business.usage_messages} / ${business.no_messages}` }}</strong>
            </div>
            <b-progress
								:value="business.no_messages !== null ? business.usage_messages : 0"
								:max="business.no_messages"
                height="25px"
								class="mb-3"
								:variant="getLimitColor(business.no_messages, business.usage_messages)"
								animated
							></b-progress>
            <h5 class="h5 mt-5">Contact limit</h5>
            <div class="text-center">
              <strong>{{ business.no_contacts === null ? 'Unlimited' : `${business.usage_contacts} / ${business.no_contacts}` }}</strong>
            </div>
            <b-progress
                :value="business.no_contacts !== null ? business.usage_contacts : 0"
                :max="business.no_contacts"
                height="25px"
                class="mb-3"
                :variant="getLimitColor(business.no_contacts, business.usage_contacts)"
                animated
              ></b-progress>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="isShowAreaModal" title="Assign your number">
      <div class="form-group">
        <div class="row">
          <div class="col-9">
            <PhoneInput
              v-model="searchParams.area_code"
              name="area_code"
              label="Area code"
              @selectedCountry="handleSelectedCountry"
            />
          </div>
          <div class="col-3">
            <label class="form-control-label"></label>
            <b-button
              variant="primary"
              class="d-block"
              :disabled="!searchParams.area_code || loadingSearch"
              @click="handleSearch"
            >
              <b-spinner v-if="loadingSearch" small />
              <span v-else>Search</span>
            </b-button>
          </div>
        </div>
      </div>
      <div class="row">
        <div
          v-for="(phone, index) in phones"
          :key="`phone-${index}`"
          class="col-6"
        >
          <div class="custom-control custom-radio mb-4">
            <input
              :id="`phone-${index}`"
              v-model="selectedPhone"
              type="radio"
              name="phone"
              class="custom-control-input"
              :value="phone.phone_number"
            />
            <label class="custom-control-label" :for="`phone-${index}`">{{
              phone.phone_number
            }}</label>
          </div>
        </div>
      </div>
      <template v-slot:modal-footer>
        <b-button variant="light" @click="isShowAreaModal = false"
          >Cancel</b-button
        >
        <b-button
          variant="primary"
          :disabled="!selectedPhone"
          @click="handleAssign"
        >
          <b-spinner v-if="loadingAssign" small />
          <span v-else>Assign</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import SMSLength from '../Common/SMSLength'

export default {
  components: { SMSLength },

  data() {
    return {
      loadingAssign: false,
      loadingSearch: false,
      isShowAreaModal: false,
      loadingSave: false,
      searchParams: {
        area_code: '',
        country: null,
      },
      phones: null,
      selectedPhone: null,
      business: {},
      user: {
        business: {},
      },
      loadingUpdate: false,
    }
  },

  computed: {
    onlyCountries() {
      if (this.business && this.business.country)
      {
        return [this.business.country]
      }
      else
        return undefined
    },

    currencies() {
      return this.$store.getters['app/currencies']
    },

    dateFormats() {
      return this.$store.getters['app/dateFormats']
    },

    timezones() {
      return this.$store.getters['app/timezones']
    },
  },

  mounted() {
    this.user = Vue.util.extend({}, this.$store.getters['auth/user'])
    this.business = Vue.util.extend({}, this.user.business)
  },

  methods: {
    getLimitColor(limit, value)
    {
      if (limit <= value )
      {
        return 'danger'
      } else if (limit * 0.9 <= value && value < limit)
      {
        return 'warning'
      }
      return 'primary'
    },
    handleSearch() {
      this.loadingSearch = true

      this.$store
        .dispatch('business/searchTwilionumbers', this.searchParams)
        .then((phones) => {
          this.phones = phones
          this.loadingSearch = false
        })
        .catch(() => {
          this.loadingSearch = false
        })
    },

    handleAssign() {
      this.loadingAssign = true

      this.$store
        .dispatch('business/assignTwilionumber', this.selectedPhone)
        .then(() => {
          this.isShowAreaModal = false
          this.loadingAssign = false
        })
        .catch(() => {
          this.loadingAssign = false
        })
    },

    updateSettings() {
      this.loadingSave = true
      const requestParam = {
        name: this.business.name,
        phone_number: this.user.phone_number,
        currency: this.business.currency,
        date_format: this.business.date_format,
        auto_responder_message: this.business.auto_responder_message,
        opt_message: this.business.opt_message,
        timezone: this.business.timezone,
      }
      this.$store
        .dispatch('business/editSettings', requestParam)
        .then(() => {
          this.loadingSave = false
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.$refs.settingsForm.setErrors(err.response.data.errors)
          }
          this.loadingSave = false
        })
    },

    showAreaModal() {
      this.isShowAreaModal = true
    },

    handleSelectedCountry(country) {
      this.searchParams.country = country
    },

    addBroadcaster() {
      this.$refs.broadcastersForm.validate().then((valid) => {
        if (valid) {
          this.user.business.broadcasters.push({
            id: null,
            name: null,
            phone_number: null,
          })
        }
      })
    },

    removeBroadcaster(index) {
      this.user.business.broadcasters.splice(index, 1)
    },

    updateBroadcasters() {
      this.$refs.broadcastersForm.validate().then((valid) => {
        if (valid) {
          this.loadingUpdate = true

          this.$store
            .dispatch('business/updateBroadcasters', {
              broadcasters: this.user.business.broadcasters,
            })
            .then(() => {
              this.loadingUpdate = false
            })
            .catch(() => {
              this.loadingUpdate = false
            })
        }
      })
    },
  },
}
</script>
